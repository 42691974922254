import React from 'react';
import PropTypes from 'prop-types';

import { Autoplay, EffectFade, Lazy, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/lazy/lazy.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';

import MediaQuery from 'react-responsive';

import URL from 'url-parse';
import { Link } from 'found';

import GA4 from '~/ga4';

export default class Banner extends React.Component {
  static propTypes = {
    banners: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        bg1: PropTypes.string,
        main1: PropTypes.string,
        main2: PropTypes.string,
        link: PropTypes.string,
      }),
    })),
    changeBannerBg: PropTypes.func.isRequired,
  };

  static defaultProps = {
    banners: [],
  };

  constructor(props) {
    super(props);

    const { changeBannerBg }= this.props;
    this.params = {
      modules: [Autoplay, EffectFade, Lazy, Pagination],
      lazy: true,
      loop: true,
      grabCursor: true,
      effect: 'fade',
      autoplay: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      onSlideChange: (s) => {
        if (s.realIndex !== null) {
          changeBannerBg(s.realIndex);
        }
      },
    };

    // reset index to 0 on every mounting
    this.props.changeBannerBg(0);
  }

  onClick = (i, index) => {
    GA4.selectPromotion(i, index);
  }

  getLink = (i, index) => {
    const url = new URL(i.link);

    let props1 = {};
    let props2 = {};

    // let first n banners download
    // the rest of banners should be lazyloaded
    const n = 3;
    if (index < n) {
      props1 = {
        className: "img-fluid",
        src: i.main1,
      };
      props2 = {
        className: "img-fluid",
        src: i.main2,
      };
    }
    else {
      props1 = {
        className: "swiper-lazy img-fluid",
        style: {maxHeight: '460px'},
        'data-src': i.main1,
      };
      props2 = {
        className: "swiper-lazy img-fluid",
        style: {maxHeight: '460px'},
        'data-src': i.main2,
      };
    }

    const children = (
      <div>
        <MediaQuery minWidth={768}>
          <img alt={i.title} {...props1} />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <img alt={i.title}  {...props2} />
        </MediaQuery>
      </div>
    );

    if (window.location.host === url.host) {
      return (
        <Link onClick={() => { this.onClick(i, index); }} to={url.pathname + url.query} style={{ display: 'block', maxWidth: '1120px', margin: '0 auto' }}>
          {children}
        </Link>
      );
    }

    return (
      <a onClick={() => { this.onClick(i, index); }} href={i.link} style={{ display: 'block', maxWidth: '1120px', margin: '0 auto' }}>
        {children}
      </a>
    );

  }

  renderBanners = () => {
    const { banners } = this.props;

    return (
      <div>
        {banners.length > 0 && (
        <Swiper
          {...this.params}
        >
          {banners.map((edge, index) => {
            const i = edge.node;
            return (
              <SwiperSlide key={i.id}>
                <div style={{ background: `url(${i.bg1}) no-repeat center top`, backgroundSize: 'cover', }}>
                  {this.getLink(i, index)}
                </div>
              </SwiperSlide>
            );
          })}
          <div className="swiper-pagination" />
        </Swiper>
        )}
      </div>
    );
  }

  render() {
    return this.renderBanners();
  }
}
